import { kebabCase } from "lodash";

import { CountryCode2, DEFAULT_COUNTRY_CODE } from "~/components/CountrySelector";
import { Country } from "~/types/country.types";

import countries from "./countries.json";

export const getCountriesByLocales = (locale: string): Country[] => {
  const displayNames = new Intl.DisplayNames([locale], { type: "region" });

  return (countries as Country[]).map((country) => ({
    ...country,
    name: displayNames.of(country.code2) ?? "",
  }));
};

export const getCountriesMap = (
  locale: string,
  field: keyof Country
): Record<string, Country> => {
  const countries = getCountriesByLocales(locale);

  return countries.reduce((accumulator: Record<string, Country>, country) => {
    accumulator[country[field] as string] = country;
    return accumulator;
  }, {});
};

export const getCountriesRoutes = (locale: string): Record<string, Country> => {
  const displayNames = new Intl.DisplayNames([locale], { type: "region" });
  const countries = getCountriesByLocales(locale);

  return countries.reduce((accumulator: Record<string, Country>, country) => {
    accumulator[kebabCase(displayNames.of(country.code2))] = country;
    return accumulator;
  }, {});
};

export const getCountry = (locale: string): Country => {
  const countries = getCountriesByLocales(locale);
  const countryCode = locale.split("-").pop();

  return (
    countries.find(
      (country) => country.code2.toLowerCase() === countryCode?.toLowerCase()
    ) ?? {
      code2: DEFAULT_COUNTRY_CODE,
      code3: "",
      name: "",
      number: "",
    }
  );
};

export const getCountryCode2 = (locale: string): CountryCode2 => {
  const country = getCountry(locale);
  return country.code2;
};

/* eslint @typescript-eslint/no-var-requires: "off" */

const sha256: (
  input: string | ArrayBuffer | ArrayBufferView,
  options?: { outputFormat: string }
) => Promise<string> = (() => {
  /**
   * There is an issue with building crypto-hash wia Next 14
   * According to recomendation in https://github.com/apollographql/apollo-client/issues/11551, defined condition to use native node crypto instead library
   */
  if (IS_SERVER) {
    const crypto = require("node:crypto");

    return async function (
      input: string | ArrayBuffer | ArrayBufferView,
      options?: { outputFormat: string }
    ) {
      const hash = crypto.createHash("sha256");
      hash.update(input);
      return hash.digest(options?.outputFormat ?? "hex");
    };
  } else {
    const crypto = require("crypto-hash");
    return crypto.sha256;
  }
})();

export { sha256 };

import { shouldPolyfill } from "@formatjs/intl-displaynames/should-polyfill";

import { DEFAULT_LOCALE } from "~/constants/i18n";

import { getPageLocale } from "./locales";

export async function awaitIntlDisplayNamesPolyfill(
  locale: string = DEFAULT_LOCALE
) {
  const pageLocale = getPageLocale(locale);
  const unsupportedLocale = shouldPolyfill(pageLocale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import("@formatjs/intl-displaynames/polyfill-force");
  if (typeof unsupportedLocale === "string") {
    await import(`@formatjs/intl-displaynames/locale-data/${unsupportedLocale}`);
  }
}

interface GlobalsProps {
  windowVar: keyof Window;
  maxAttempts?: number;
  timeout?: number;
}

export const awaitGlobalsInitialization = async ({
  windowVar,
  maxAttempts = 100,
  timeout = 100,
}: GlobalsProps): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    // eslint-disable-next-line no-restricted-globals
    if (typeof window === "undefined") {
      reject("Client only");
    }
    let attempt = 0;

    const intervalID = setInterval(() => {
      // eslint-disable-next-line no-restricted-globals
      const isGlobalVarDefined = typeof window[windowVar] !== "undefined";
      if (attempt > maxAttempts) {
        clearInterval(intervalID);
        reject(`Failed to initialize ${windowVar as string}`);
      }

      if (isGlobalVarDefined) {
        clearInterval(intervalID);
        resolve(`${windowVar as string} initialized`);
      }
      attempt++;
    }, timeout);
  });
};

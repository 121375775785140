"use client";

/* eslint no-process-env: 0,  */
import React, { useCallback, useEffect, useRef } from "react";

import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material";
import { Redirect } from "next";
import { useRouter, useParams } from "next/navigation";

import { APOLLO_CLIENT } from "~/graphql/client";
import { BrowserDataProvider } from "~/hooks/useBrowserData";
import { THEME } from "~/theme";
import "~/styles/globals.css";
import { awaitGlobalsInitialization } from "~/utils/awaitGlobalsInitialization";
import { awaitIntlDisplayNamesPolyfill } from "~/utils/awaitIntlDisplayNamesPolyfill";
import { getCountryLangFromLocale, getPageLocale } from "~/utils/locales";
import {
  alignCategoryCheckboxWithTerritoryCookie,
  loadCookieBanner,
} from "~/utils/onetrust/oneTrustCookieBanner";
import {
  handleCookieAcceptance,
  processOneTrustTerritoryCookies,
} from "~/utils/onetrust/oneTrustTerritoryCookiesManager";

const PREVIOUS_CURRENT_LOCALES = "previousCurrentLocales";

export interface AppProps {
  children: React.ReactNode;
  processEnv: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  redirect?: Redirect;
}

export const App = (props: AppProps) => {
  const { children, processEnv, redirect } = props;
  const router = useRouter();
  const params = useParams();
  const pageLocale = getPageLocale(params?.locale as string);
  const localeRef = useRef(params?.locale as string);

  useEffect(() => {
    if (!localStorage.getItem(PREVIOUS_CURRENT_LOCALES)) {
      localStorage.setItem(PREVIOUS_CURRENT_LOCALES, `${pageLocale},${pageLocale}`);
    }

    const locales = localStorage.getItem(PREVIOUS_CURRENT_LOCALES)?.split(",");

    if (pageLocale !== localeRef.current || pageLocale !== locales?.[1]) {
      localStorage.setItem(
        PREVIOUS_CURRENT_LOCALES,
        `${locales?.[1]},${pageLocale}`
      );
    } else {
      localStorage.setItem(PREVIOUS_CURRENT_LOCALES, `${pageLocale},${pageLocale}`);
    }
    localeRef.current = pageLocale;
  }, [pageLocale]);

  useEffect(() => {
    (async () => {
      await awaitIntlDisplayNamesPolyfill(pageLocale);
      await awaitGlobalsInitialization({ windowVar: "OneTrust" });

      const prevLocale =
        localStorage.getItem(PREVIOUS_CURRENT_LOCALES)?.split(",")[0] ?? pageLocale;

      if (pageLocale !== prevLocale) {
        const lang = getCountryLangFromLocale(pageLocale);
        processOneTrustTerritoryCookies(pageLocale, prevLocale);
        /* @ts-expect-error Due to undefined*/
        OneTrust.changeLanguage(lang);

        /* @ts-expect-error Due to undefined*/
        if (OneTrust.IsAlertBoxClosed()) {
          const bannerElement = document?.getElementById("onetrust-banner-sdk");
          const fadeElement = document
            ?.getElementsByClassName("onetrust-pc-dark-filter")
            ?.item(0);
          if (bannerElement) {
            bannerElement.remove();
          }
          if (fadeElement) {
            fadeElement.setAttribute("style", "display: none");
          }

          setTimeout(() => {
            /* @ts-expect-error Due to undefined*/
            OneTrust.Init();
            alignCategoryCheckboxWithTerritoryCookie();
          }, 1000);
        }
      }
    })();
  }, [pageLocale]);

  const listener = useCallback(() => {
    if (localeRef.current) {
      handleCookieAcceptance(localeRef.current);
    }
  }, []);

  useEffect(() => {
    if (!IS_SERVER) {
      awaitGlobalsInitialization({ windowVar: "OneTrust" }).then(() => {
        // eslint-disable-next-line no-restricted-globals
        window?.addEventListener("consent.onetrust", listener);
      });

      return () => {
        // eslint-disable-next-line no-restricted-globals
        window?.removeEventListener("consent.onetrust", listener);
      };
    }
  }, [listener]);

  useEffect(() => {
    if (!IS_SERVER) {
      awaitGlobalsInitialization({ windowVar: "OneTrust" }).then(() => {
        setTimeout(() => {
          loadCookieBanner();
        }, 1000);
      });
    }
  }, []);

  if (!IS_SERVER) {
    if (!process.env.IS_READY) {
      process.env = {
        ...process.env,
        ...processEnv,
        IS_READY: true,
      };
    }

    if (redirect) {
      router.replace(redirect.destination);
      return null;
    }
  }

  return (
    <div>
      <ApolloProvider client={APOLLO_CLIENT}>
        <ThemeProvider theme={THEME}>
          <BrowserDataProvider>{children}</BrowserDataProvider>
        </ThemeProvider>
      </ApolloProvider>
    </div>
  );
};

import Cookies from "js-cookie";
import { merge } from "lodash";

import {
  OPTANON_ALERT_BOX_CLOSED,
  OPTANON_CONSENT_COOKIES,
} from "~/constants/userCookies";
import { AzureConfigurator } from "~/services/azureConfiguration";
import { getCountryCode2 } from "~/utils/countries";

export const STORAGE_KEY = "oneTrustTerritoryCookies";
export const COOKIE_KEYS = [OPTANON_CONSENT_COOKIES, OPTANON_ALERT_BOX_CLOSED];

type TerritoryCookies = Record<string, string>;
type TerritoryCookiesStore = Record<string, TerritoryCookies | null>;

const getOneTrustCookiesToSaveInStore = (): TerritoryCookies | null => {
  const cookiesToSave: TerritoryCookies = {};

  for (const cookieKey of COOKIE_KEYS) {
    if (typeof Cookies.get(cookieKey) === "string") {
      cookiesToSave[cookieKey] = Cookies.get(cookieKey) as string;
    }
  }

  const areRequiredCookiesPresented =
    Object.keys(cookiesToSave).length === COOKIE_KEYS.length;
  return areRequiredCookiesPresented ? cookiesToSave : null;
};

const getCookiesStore = (): TerritoryCookiesStore | null => {
  const json = localStorage.getItem(STORAGE_KEY);
  if (json === null) {
    return null;
  }

  try {
    const store = JSON.parse(json);
    const countries = Object.keys(store).filter((countryCode) => store[countryCode]);
    for (const countryCode of countries) {
      for (const cookieKey of COOKIE_KEYS) {
        if (typeof store[countryCode][cookieKey] !== "string") {
          return null;
        }
      }
    }
    return store;
  } catch {
    return null;
  }
};

const initializeCookiesStore = (country = "GB") => {
  const cookiesToSaveInStore = getOneTrustCookiesToSaveInStore();
  if (cookiesToSaveInStore) {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        [country]: cookiesToSaveInStore,
      })
    );
  } else {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({}));
  }
};

const updateCookiesStore = (value: TerritoryCookiesStore) => {
  const store = getCookiesStore();
  localStorage.setItem(STORAGE_KEY, JSON.stringify(merge(store, value)));
};

const isFirstLoadAfterTerritoryUpdate = (): boolean => {
  return localStorage.getItem(STORAGE_KEY) === null;
};

const setOneTrustCookies = (
  cookiesFromStore: TerritoryCookies,
  cookieDomain: string
): void => {
  for (const cookieKey in cookiesFromStore) {
    Cookies.set(
      cookieKey,
      cookiesFromStore[cookieKey],
      cookieDomain
        ? {
            path: "/",
            domain: cookieDomain,
          }
        : undefined
    );
  }
};

const clearOneTrustCookies = (cookieDomain: string) => {
  COOKIE_KEYS.forEach((cookieKey) =>
    Cookies.remove(
      cookieKey,
      cookieDomain
        ? {
            path: "/",
            domain: cookieDomain,
          }
        : undefined
    )
  );
};

export const processOneTrustTerritoryCookies = (
  locale: string,
  prevLocale: string
): void => {
  const countryCode = getCountryCode2(locale);
  const prevCountryCode = getCountryCode2(prevLocale);
  const cookiesToSaveInStore = getOneTrustCookiesToSaveInStore();
  const cookieDomain = AzureConfigurator.getConfig(locale)?.cookieDomain as string;

  if (cookiesToSaveInStore === null) {
    updateCookiesStore({
      [prevCountryCode]: null,
    });
  }

  if (!isFirstLoadAfterTerritoryUpdate()) {
    clearOneTrustCookies(cookieDomain);
  }

  const cookiesStore = getCookiesStore();
  if (cookiesStore === null) {
    initializeCookiesStore(countryCode);
    return;
  }

  const countryCookies = cookiesStore[countryCode];
  if (countryCookies) {
    setOneTrustCookies(countryCookies, cookieDomain);
  }
};

export const handleCookieAcceptance = (locale: string): void => {
  const countryCode = getCountryCode2(locale);
  const cookiesToSaveInStore = getOneTrustCookiesToSaveInStore();
  if (cookiesToSaveInStore) {
    updateCookiesStore({
      [countryCode]: cookiesToSaveInStore,
    });
  }
};

import {
  ApolloClient,
  ApolloClientOptions,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { DefaultOptions } from "@apollo/client/core/ApolloClient";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import fetch from "cross-fetch";

import { AzureConfigurator } from "~/services/azureConfiguration";
import { getOverridesForLocalDevelopment } from "~/services/azureConfiguration/azureConfiguration.constants";
import { sha256 } from "~/utils/hash";

interface ExtraOptions {
  locale?: string;
}

export const getApolloClientOptions = (
  options?: DefaultOptions & ExtraOptions
): ApolloClientOptions<NormalizedCacheObject> => ({
  uri: IS_TEST
    ? AzureConfigurator.getConfig(options?.locale)?.apiBaseUrl ?? undefined
    : undefined,
  link: !IS_TEST
    ? createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(
        new HttpLink({
          uri: AzureConfigurator.getConfig(options?.locale)?.apiBaseUrl ?? undefined,
          credentials: "include",
          fetchOptions: { cache: "no-store" },
        })
      )
    : new HttpLink({
        uri:
          getOverridesForLocalDevelopment().apiBaseUrl ?? "http://localhost:3001/",
        fetch,
      }),
  ssrMode: IS_SERVER,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export function getApolloClient() {
  const options = getApolloClientOptions();
  return new ApolloClient(options);
}

export const APOLLO_CLIENT = getApolloClient();
